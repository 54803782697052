import { FarcasterAuthorizeRequest } from "@/api/sdk"
import Modal from "@/components/Modal"
import { useClearUrlQuery } from "@/hooks/useQuery"
import { googleAnalytics } from "@/lib/gtag"
import { useSignInStore } from "@/stores"
import { useWeb3ModalState } from "@web3modal/wagmi/react"
import { AnimatePresence } from "framer-motion"
import Link from "next/link"
import useCustomRouter from "@/hooks/useCustomRouter"
import { Fragment, useCallback, useEffect, useState } from "react"
import LinkAccount from "../SignUpModalV2/LinkAccount"
import LinkAccountEvm from "../SignUpModalV2/LinkAccountEvm"
import LinkFarcaster from "../SignUpModalV2/LinkFarcaster"
import ForgotPassword from "./ForgotPassword"
import SignInWithPasscode from "./SignInWithPasscode"
import SignInWithPassword from "./SignInWithPassword"
import SignInWithThirdParty from "./SignInWithThirdParty"
import { cdnPublicFolderUrl } from "@/constants"

export type SignInFormType = {
  email: string
  password?: string
  passcode?: string
}

const SignInModalV2 = ({ onClose: onCloseOutside }: { onClose?: () => void }) => {
  const [modeSignIn, setModeSignIn] = useState<"password" | "passcode" | "verify">("password")
  const [mode, setMode] = useState<
    "sign-in" | "forgot-password" | "link-wallet" | "link-wallet-evm" | "link-farcaster"
  >("sign-in")
  const [farcasterPayload, setFarcasterPayload] = useState<FarcasterAuthorizeRequest | null>(null)
  const router = useCustomRouter()
  const clearUrl = useClearUrlQuery()
  const { setSignInModal, signInModal } = useSignInStore()
  const isOpen =
    (signInModal.signIn ||
      router.query.signIn === "true" ||
      router.query.forgotPassword === "true" ||
      signInModal.forgotPassword) ??
    false

  const onClose = useCallback(() => {
    document.body.style.overflow = "unset"

    setSignInModal({
      signIn: false,
      forgotPassword: false,
    })
    setModeSignIn("password")
    setMode("sign-in")

    if (router.query.signIn === "true" || router.query.forgotPassword === "true") {
      clearUrl(["signIn", "forgotPassword"])
    }
  }, [router.query, clearUrl, setSignInModal])

  useEffect(() => {
    if (router.query.signIn === "true" || signInModal.signIn) {
      setMode("sign-in")
    } else if (router.query.forgotPassword === "true" || signInModal.forgotPassword) {
      setMode("forgot-password")
    }
  }, [router.query, , signInModal])

  const renderForm = () => {
    switch (mode) {
      case "forgot-password":
        return <ForgotPassword />

      case "link-wallet":
        return <LinkAccount onChangeMode={() => setMode("sign-in")} />

      case "link-wallet-evm":
        return <LinkAccountEvm onChangeMode={() => setMode("sign-in")} />

      case "link-farcaster":
        return <LinkFarcaster onChangeMode={() => setMode("sign-in")} payload={farcasterPayload} />

      default:
        return (
          <Fragment>
            <AnimatePresence mode="popLayout">{renderModeSignIn()}</AnimatePresence>
            <div className="flex-1 flex flex-col justify-end">
              <SignInWithThirdParty
                onClose={onClose}
                onChangeLinkWallet={(mode = "link-wallet") => setMode(mode)}
                onChangeLinkFarcaster={payload => {
                  setFarcasterPayload(payload)
                  setMode("link-farcaster")
                }}
              />
              <hr className="my-4 border-atherGray-800" />
              <div className="text-center">
                <p className="text-atherGray-500 text-xs">
                  Don&apos;t have an account?{" "}
                  <Link
                    href={{
                      query: {
                        signUp: true,
                        next: router.query.next,
                      },
                    }}
                    shallow
                    onClick={e => {
                      e.preventDefault()

                      googleAnalytics.event({
                        action: "click",
                        label: "sign_up",
                        category: "sign_in",
                      })

                      if (router.query.signIn === "true") {
                        router.replace(
                          {
                            query: {
                              signUp: true,
                              next: router.query.next,
                            },
                          },
                          undefined,
                          {
                            shallow: true,
                          },
                        )
                        return
                      }

                      setSignInModal({
                        signIn: false,
                        forgotPassword: false,
                        signUp: true,
                      })
                    }}
                    className="font-semibold text-atherGray-0"
                  >
                    Sign Up
                  </Link>
                </p>
              </div>
            </div>
          </Fragment>
        )
    }
  }

  const renderModeSignIn = () => {
    switch (modeSignIn) {
      case "password":
        return <SignInWithPassword onClose={onClose} onChangeMode={() => setModeSignIn("passcode")} />
      case "passcode":
        return <SignInWithPasscode onClose={onClose} onChangeMode={() => setModeSignIn("password")} />
    }
  }

  const { open: openWalletModal } = useWeb3ModalState()

  return (
    <Modal
      isDisabledRemoveScroll={openWalletModal}
      closeButtonClassName="absolute top-2 bg-blackAlpha-600 right-2 z-[1] text-white"
      className="bg-[#191918] rounded-xl max-w-[60rem] h-auto shadow-md p-0 modal-content-child"
      bodyClassName="p-0 justify-center"
      containerClassName={"overflow-auto p-4"}
      isOpen={isOpen}
      onClose={() => {
        onCloseOutside?.()
        onClose()
      }}
    >
      <div className="flex flex-col md:flex-row">
        <div className="flex-1 hidden md:flex relative">
          <img className="object-cover h-full m-auto" src={`${cdnPublicFolderUrl}/images/banner.png`} alt="Banner" />
          <div
            className="absolute top-0 left-0 w-full h-[200px]"
            style={{
              background: "linear-gradient(180deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.00) 100%)",
            }}
          />
          <div className="max-w-[10rem] mb-12 absolute top-8 left-8">
            <img src={`${cdnPublicFolderUrl}/logo.png`} alt="Ather Logo" width={88.44} height={44} />
          </div>
        </div>
        <div className="flex-1 flex flex-col">
          <div className="py-6 px-6 md:py-8 md:px-14 w-full flex-1 flex flex-col">{renderForm()}</div>
        </div>
      </div>
    </Modal>
  )
}

export default SignInModalV2
