import IconButton from "@/components/IconButton"
import Input from "@/components/Input"
import { EyeCloseIcon, EyeIcon } from "@/components/shared/icons"
import React, { forwardRef, memo, useState } from "react"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { motion } from "framer-motion"
import { cn } from "@/utils/cn"
import Link from "next/link"
import { signInWithEmailAndPassword } from "firebase/auth"
import { auth } from "@/lib/firebaseClient"
import { useToast } from "@/hooks"
import useReferralUser from "./useReferralUser"
import { useSignInStore } from "@/stores"
import useCustomRouter from "@/hooks/useCustomRouter"
import { googleAnalytics } from "@/lib/gtag"
import { isEmailAddress } from "@/utils/is-email-address"

interface SignInWithPasswordProps {
  onChangeMode: () => void
  onClose: () => void
}

type SignInPasswordType = {
  email: string
  password: string
}

const SignInWithPassword = forwardRef<HTMLInputElement, SignInWithPasswordProps>(({ onChangeMode, onClose }, ref) => {
  const form = useForm<SignInPasswordType>({
    mode: "onSubmit",
  })

  const {
    register,
    getValues,
    clearErrors,
    setError,
    formState: { errors },
  } = form
  const { email, password } = useWatch({ control: form.control })
  const [showPassword, setShowPassword] = useState(false)
  const isDisabled = !email || !password
  const toast = useToast()
  const { handleSignInSuccess } = useReferralUser(onClose)
  const [isLoading, setIsLoading] = useState(false)
  const setSignInModal = useSignInStore(state => state.setSignInModal)
  const router = useCustomRouter()

  const signInWithEmailPassword = async (data: SignInPasswordType) => {
    try {
      setIsLoading(true)
      const { email, password } = data
      const credentials = await signInWithEmailAndPassword(auth, email, password)

      if (!credentials.user.emailVerified) {
        toast({
          status: "error",
          title: "Error",
          message: ["Your email is not verified"],
        })
        setIsLoading(false)

        return
      }

      await handleSignInSuccess({ credentials, provider: "email" })

      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      console.error("Error signing in with email and password", error)

      if (error.code === "auth/user-not-found") {
        toast({
          status: "error",
          title: "Error",
          message: ["This account is not registered yet"],
        })
        return
      }

      if (error.code === "auth/wrong-password") {
        toast({
          status: "error",
          title: "Error",
          message: ["Please check your password and try again"],
        })
        return
      }

      if (error.code === 400) {
        toast({
          status: "error",
          title: "Error",
          message: ["Too many unsuccessful login attempts. Please try again later"],
        })
        return
      }

      toast({
        status: "error",
        title: "Error",
        message: ["Something went wrong, please try again"],
      })
    }
  }

  const submit = async e => {
    e.preventDefault()

    googleAnalytics.event({
      action: "click",
      label: "sign_in_password",
      category: "sign_in",
      params: {
        email: email ?? "",
      },
    })

    const data = getValues()
    let isValidate = true
    clearErrors()

    //regex email
    if (!isEmailAddress(data.email)) {
      setError("email", {
        message: "Invalid email address",
      })
      isValidate = false
    }

    if (!isValidate) return

    await signInWithEmailPassword(data)
  }

  return (
    <FormProvider {...form}>
      <form id="sign-in-passcode" onSubmit={submit}>
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -20 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20,
          }}
        >
          <h3 className="text-xl text-center font-semibold mb-6">Sign In</h3>
          <div className="space-y-4 w-full">
            <div>
              <Input {...register("email")} placeholder="Email address" className="placeholder:text-atherGray-500" />
              {errors.email && <p className="text-[0.65rem] mt-1 leading-4 text-red-500">{errors.email.message}</p>}
            </div>
            <div>
              <Input
                {...register("password")}
                rightIcon={
                  <button
                    className={cn("", {
                      "text-atherGray-500": !password,
                      "text-atherGray-0": password,
                    })}
                    type="button"
                    onClick={() => setShowPassword(prev => !prev)}
                  >
                    {showPassword ? <EyeIcon /> : <EyeCloseIcon />}
                  </button>
                }
                placeholder="Password"
                className="placeholder:text-atherGray-500"
                type={showPassword ? "text" : "password"}
              />
              {errors.password && (
                <p className="text-[0.65rem] mt-1 leading-4 text-red-500">{errors.password.message}</p>
              )}
              <Link
                href={{
                  query: {
                    forgotPassword: true,
                    next: router.query.next,
                  },
                }}
                onClick={e => {
                  e.preventDefault()
                  if (router.query.signIn === "true") {
                    router.replace(
                      {
                        query: {
                          forgotPassword: true,
                          next: router.query.next,
                        },
                      },
                      undefined,
                      {
                        shallow: true,
                      },
                    )
                    return
                  }

                  setSignInModal({
                    signIn: false,
                    forgotPassword: true,
                    signUp: false,
                  })
                }}
                shallow
                className="text-xs text-atherGray-500 mt-2 block"
              >
                Forgot password?
              </Link>
            </div>
            <IconButton isLoading={isLoading} disabled={isDisabled} type="submit" className="w-full py-3 rounded-xl">
              Sign In
            </IconButton>
            <div className="text-center">
              <button type="button" onClick={onChangeMode} className="font-semibold text-xs">
                Sign in with Passcode
              </button>
            </div>
          </div>
        </motion.div>
      </form>
    </FormProvider>
  )
})

export default memo(SignInWithPassword)
