import IconButton from "@/components/IconButton"
import Input from "@/components/Input"
import PasscodeInput from "@/components/Setting/Security/PasscodeInput"
import { motion } from "framer-motion"
import React, { forwardRef, memo, useState } from "react"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import useReferralUser from "./useReferralUser"
import { useMutation } from "@tanstack/react-query"
import client from "@/api/client"
import { useToast } from "@/hooks"
import { signInWithCustomToken } from "firebase/auth"
import { auth } from "@/lib/firebaseClient"
import { googleAnalytics } from "@/lib/gtag"
import { isEmailAddress } from "@/utils/is-email-address"

interface SignInWithPasscodeProps {
  onChangeMode: () => void
  onClose: () => void
}

type SignInPasscodeType = {
  email: string
  code: string
}

const SignInWithPasscode = forwardRef<HTMLInputElement, SignInWithPasscodeProps>(({ onChangeMode, onClose }, ref) => {
  const toast = useToast()
  const form = useForm<SignInPasscodeType>({
    mode: "onSubmit",
  })
  const {
    register,
    setValue,
    getValues,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = form

  const { handleSignInSuccess } = useReferralUser(onClose)

  const [isLoading, setIsLoading] = useState(false)

  const { email, code } = useWatch({ control: form.control })

  const isDisabled = !code || code.length < 6 || !email

  const { mutate: mutateSendSignInEmailPasscode, isPending: isSending } = useMutation({
    mutationFn: (email: string) =>
      client.api.authControllerSendSignInEmailPasscode({
        email,
      }),
    onSuccess: () => {
      toast({
        status: "success",
        title: "Success",
        message: ["Email sent"],
      })
    },
    onError: (error: any) => {
      toast({
        status: "error",
        title: "Error",
        message: [error?.message ?? "Error sending email confirmation"],
      })
    },
  })

  const { mutate: mutateVerifySignInEmailPasscode } = useMutation({
    mutationFn: (data: SignInPasscodeType) =>
      client.api.authControllerVerifySignInEmailPasscode({
        email: data.email,
        code: data.code,
      }),
    onSuccess: async ({ data }) => {
      const { customToken } = data
      const credentials = await signInWithCustomToken(auth, customToken)
      await handleSignInSuccess({ credentials, provider: "email" })

      setIsLoading(false)
    },
    onError: (error: any) => {
      setIsLoading(false)
      toast({
        status: "error",
        title: "Error",
        message: [error?.message],
      })
    },
  })

  const handleResendPasscode = async e => {
    e.preventDefault()
    e.stopPropagation()

    if (!email) {
      toast({
        status: "error",
        title: "Error",
        message: ["Email is required"],
      })

      return
    }

    googleAnalytics.event({
      action: "click",
      label: "send_code",
      category: "sign_in",
    })
    try {
      mutateSendSignInEmailPasscode(email)
    } catch (error) {
      console.error("Error sending email confirmation", error)
    }
  }

  const submit = e => {
    e.preventDefault()

    googleAnalytics.event({
      action: "click",
      label: "sign_in_passcode",
      category: "sign_in",
      params: {
        email: email ?? "",
      },
    })

    const data = getValues()
    clearErrors()
    let isValidate = true

    //regex email

    if (!isEmailAddress(data.email)) {
      setError("email", {
        message: "Invalid email address",
      })
      isValidate = false
    }

    if (!isValidate) return
    setIsLoading(true)
    mutateVerifySignInEmailPasscode(data)
  }

  return (
    <FormProvider {...form}>
      <form id="sign-in-passcode" onSubmit={submit}>
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 20 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20,
          }}
        >
          <h3 className="text-xl text-center font-semibold mb-6">Sign In</h3>
          <div className="space-y-4 w-full">
            <div>
              <Input {...register("email")} placeholder="Email address" className="placeholder:text-atherGray-500" />
              {errors.email && <p className="text-[0.65rem] mt-1 leading-4 text-red-500">{errors.email.message}</p>}
            </div>
            <div className="min-h-[64px]">
              <p className="text-xs text-atherGray-500">Enter the 6-digit code we sent to your email</p>
              <div className="flex flex-col h-full md:flex-row items-center">
                <PasscodeInput
                  value={code}
                  onChange={v => setValue("code", v)}
                  className="w-8 h-8 text-xl text-atherGray-0 bg-atherGray-900 rounded-none border-atherGray-800 border-b-[2px]"
                />
                <div className="flex items-center justify-center h-10 w-[7rem] mt-4 md:mt-0 md:ml-4 cursor-pointer">
                  <IconButton
                    isLoading={isSending}
                    colorScheme="transparent"
                    className="text-atherPurple-300 p-0 bg-atherGray-900 text-sm font-semibold"
                    onClick={handleResendPasscode}
                  >
                    Send Code
                  </IconButton>
                </div>
              </div>
              {errors.code && <p className="text-[0.65rem] mt-1 leading-4 text-red-500">{errors.code.message}</p>}
            </div>
            <IconButton isLoading={isLoading} disabled={isDisabled} type="submit" className="w-full py-3 rounded-xl">
              Sign In
            </IconButton>
            <div className="text-center">
              <button type="button" onClick={onChangeMode} className="font-semibold text-xs">
                Sign in with Password
              </button>
            </div>
          </div>
        </motion.div>
      </form>
    </FormProvider>
  )
})

export default memo(SignInWithPasscode)
