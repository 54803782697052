import client from "@/api/client"
import IconButton from "@/components/IconButton"
import Input from "@/components/Input"
import PasscodeInput from "@/components/Setting/Security/PasscodeInput"
import { EyeIcon, EyeCloseIcon } from "@/components/shared/icons"
import { useToast } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useSignInStore } from "@/stores"
import { cn } from "@/utils/cn"
import { isAcceptPassword, isEmailAddress } from "@/utils/is-email-address"
import { useMutation } from "@tanstack/react-query"
import { motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import React, { useState } from "react"
import { FormProvider, useForm, useWatch } from "react-hook-form"

type ResetPasswordInputType = {
  email: string
  code: string
  password: string
}

const ForgotPassword = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [mode, setMode] = useState<"email" | "passcode" | "final">("email")
  const router = useCustomRouter()
  const toast = useToast()
  const setSignInModal = useSignInStore(state => state.setSignInModal)

  const form = useForm<ResetPasswordInputType>({
    mode: "onSubmit",
  })

  const {
    formState: { errors },
    setError,
  } = form

  const { code, email, password } = useWatch({
    control: form.control,
  })

  const { mutate: mutateSendResetPasswordPasscode, isPending: isSending } = useMutation({
    mutationFn: ({ email }: { email: string; isVerified?: boolean }) =>
      client.api.authControllerResetPassword({
        email,
      }),
    onSuccess: (_, { isVerified }) => {
      if (isVerified) {
        setMode("passcode")
        return
      }

      toast({
        status: "success",
        title: "Success",
        message: ["Email sent"],
      })
    },
    onError: (error: any) => {
      toast({
        status: "error",
        title: "Error",
        message: [error?.message ?? "Error sending email confirmation"],
      })
    },
  })

  const { mutate: mutateVerifyResetPassword, isPending: isVerifyingResetPassword } = useMutation({
    mutationFn: (data: ResetPasswordInputType) =>
      client.api.authControllerVerifyResetPassword({
        email: data.email,
        code: data.code,
        password: data.password,
      }),
    onSuccess: () => {
      toast({
        status: "success",
        title: "Success",
        message: ["Your password has been reset"],
      })
      setMode("final")
    },
    onError: (error: any) => {
      toast({
        status: "error",
        title: "Error",
        message: [error?.message],
      })
    },
  })

  const handleResendResetPasswordPasscode = async (isVerified?: boolean) => {
    const { email } = form.getValues()

    if (!email) {
      toast({
        status: "error",
        title: "Error",
        message: ["Email is required"],
      })

      return
    }

    googleAnalytics.event({
      action: "click",
      label: "send_code",
      category: "forgot_password",
      params: {
        email: email ?? "",
      },
    })

    try {
      mutateSendResetPasswordPasscode({
        email,
        isVerified,
      })
    } catch (error) {
      console.error("Error sending email confirmation", error)
    }
  }

  const submit = async e => {
    e.preventDefault()
    form.clearErrors()
    const { email, code, password } = form.getValues()

    let isValidate = true
    const trigger = await form.trigger()

    googleAnalytics.event({
      action: "click",
      label: "submit",
      category: "forgot_password",
      params: {
        email: email ?? "",
        mode: mode,
      },
    })

    if (mode === "email") {
      if (!isEmailAddress(email)) {
        form.setError("email", {
          message: "Invalid email address",
        })
        isValidate = false
      }

      if (!isValidate || !trigger) {
        return
      }

      handleResendResetPasswordPasscode(true)

      return
    }

    if (mode === "passcode") {
      if (password && !isAcceptPassword(password)) {
        setError("password", {
          message:
            "Password must contain at least 8 characters, including uppercase, lowercase, numbers, and special characters",
        })
        isValidate = false
      }

      if (!isValidate || !trigger) {
        return
      }

      mutateVerifyResetPassword({
        email,
        code: code,
        password: password,
      })

      return
    }

    if (mode === "final") {
      if (router.query.forgotPassword === "true") {
        router.replace(
          {
            query: {
              signIn: true,
              next: router.query.next,
            },
          },
          undefined,
          {
            shallow: true,
          },
        )
        return
      }

      setSignInModal({
        signIn: true,
      })
    }
  }

  const isDisabled = mode === "email" ? !email : mode === "passcode" ? !password || !code || code.length < 6 : false

  const renderStep = () => {
    switch (mode) {
      case "passcode":
        return (
          <div className="space-y-4">
            <div>
              <p className="text-xs text-atherGray-500 mb-4">Please enter your New Password</p>
              <Input
                {...form.register("password")}
                placeholder="New Password"
                className="placeholder:text-atherGray-500"
                rightIcon={
                  <button
                    className={cn("", {
                      "text-atherGray-500": !password,
                      "text-atherGray-0": password,
                    })}
                    type="button"
                    onClick={() => setShowPassword(prev => !prev)}
                  >
                    {showPassword ? <EyeIcon /> : <EyeCloseIcon />}
                  </button>
                }
                type={showPassword ? "text" : "password"}
              />
              {errors.password && (
                <p className="text-[0.65rem] text-red-500 mt-1 leading-4">{errors.password?.message}</p>
              )}
            </div>
            <div>
              <p className="text-xs text-atherGray-500">Enter the 6-digit code we sent to your email</p>
              <div className="flex flex-col h-full md:flex-row items-center">
                <PasscodeInput
                  value={code}
                  onChange={v => form.setValue("code", v)}
                  className="w-8 h-8 text-xl text-atherGray-0 bg-atherGray-900 rounded-none border-atherGray-800 border-b-[2px]"
                />
                <div className="flex items-center justify-center h-10 w-[7rem] mt-4 md:mt-0 md:ml-4 cursor-pointer">
                  <IconButton
                    isLoading={isSending}
                    colorScheme="transparent"
                    className="text-atherPurple-300 p-0 bg-atherGray-900 text-sm font-semibold"
                    onClick={() => handleResendResetPasswordPasscode()}
                  >
                    Send Code
                  </IconButton>
                </div>
              </div>
              {errors.code && <p className="text-[0.65rem] text-red-500 leading-4 mt-1">{errors.code?.message}</p>}
            </div>
          </div>
        )
      case "final":
        return (
          <div>
            <p className="text-xs text-atherGray-500 mb-4">You have successfully updated your account password.</p>
          </div>
        )

      default:
        return (
          <div>
            <p className="text-xs text-atherGray-500 mb-4">Enter your Email address to reset your password</p>
            <Input {...form.register("email")} placeholder="Email address" className="placeholder:text-atherGray-500" />
            {errors.email && <p className="text-[0.65rem] text-red-500 leading-4 mt-1">{errors.email?.message}</p>}
          </div>
        )
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -20 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20,
      }}
      className="flex flex-col flex-1"
    >
      <h3 className="text-xl text-center font-semibold mb-6">
        {mode === "email" ? "Forgot Password" : mode === "passcode" ? "Mostly done" : "Password updated!"}
      </h3>
      <FormProvider {...form}>
        <form onSubmit={submit}>
          {renderStep()}
          <div className="mt-8">
            <IconButton
              isLoading={isVerifyingResetPassword}
              type="submit"
              disabled={isDisabled}
              className="w-full py-3 rounded-xl"
            >
              {mode === "final" ? "Sign In" : "Continue"}
            </IconButton>
          </div>
        </form>
      </FormProvider>
      <div className="flex-1 flex flex-col justify-end">
        <hr className="my-4 border-atherGray-800" />
        <div className="text-center">
          <button
            className="text-atherGray-500 text-xs"
            onClick={() => {
              if (router.query.forgotPassword === "true") {
                router.replace(
                  {
                    query: {
                      signIn: true,
                      next: router.query.next,
                    },
                  },
                  undefined,
                  {
                    shallow: true,
                  },
                )
                return
              }
              setSignInModal({
                signIn: true,
              })
            }}
          >
            Go back
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default ForgotPassword
